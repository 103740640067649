import '../css/index.css'
import screenImg from './screen.png'
import appicon from './appicon.png'
import { Helmet } from 'react-helmet-async';

function index() {
    const newTheme = 'nopadding';
    document.body.className = newTheme;
    return (
        <div>
            <Helmet>
                <title>清波记App</title>
                {/* <link rel="icon" href="/favicon.ico" /> */}
            </Helmet>
            <div className='gradienty-pur'>
                <img className='left-image' src={screenImg} />

                <div className='right-div'>
                    <img className='appicon' src={appicon} />
                    <p className='title'>清波记App</p>
                    <div className='detail'>
                        <p>· 文字、图片、视频、语音多种记录方式</p>
                        <p>· 安全、隐私，数据加密</p>
                        <p>· 云端存储，永久保存</p>
                        <p>· 一起记，有趣</p>
                    </div>
                </div>
            </div>
            <div className='footer-pur'>
                <div style={{ display: 'flex' }}>
                    <a href='./qingboji/privacyios'><p>《用户隐私协议》</p></a>
                    <a href='./qingboji/term'><p>《使用条款》</p></a>
                </div>

                <div style={{ display: 'flex', marginTop: 12 }}>
                    <a href='https://beian.miit.gov.cn/'><p style={{ marginRight: 20, fontSize: 14 }}>粤ICP备2021177159号-2</p></a>
                    <p style={{ fontSize: 14 }}>Copyright © 2024 野离子网络科技. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default index;