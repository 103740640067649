import { useSearchParams } from 'react-router-dom';
import './daigou.css'

function Term() {
    const [searchParams] = useSearchParams();
    const theme = searchParams.get('theme');
    const language = searchParams.get('language') || '英语';
    const device = searchParams.get('device');//设备

    const newTheme = theme === 'dark' ? 'dark' : 'light';
    document.body.className = newTheme;

    if (language === '中文') {
        return (
            <div>
                <p>欢迎阅读《代购管家App用户条款》(以下简称“本条款”)。本条款阐述之条款适用于你（以下简称“用户”）使用App的各种产品和服务。</p>
                <h4>1、服务内容</h4>
                <p>1.1 代购管家App为用户提供订单、客户、商品管理服务。代购管家App有权对软件提供的服务进行升级和调整，所有功能调整均以App内容更新的方式通知用户，不单独进行通知。</p>
                <p>1.2 代购管家App不会对用户信息进行任何二次利用，进行销售等任何形式的获利。</p>
                <p>1.3 代购管家App仅提供技术相关服务，除此之外的网络相关设备（如个人手机、平板等）及所需费用（如手机流量费用、WIFI上网费用等）均由用户自行承担。</p>
                <h4>2、服务条款的修改</h4>
                <p>本网站及APP有权在必要时修改条款，将会在页面公布。如果不接受所改动的内容，用户可以主动取消自己的会员资格。如果你不取消自己的会员资格，则视为接受服务条款的变动。</p>
                <h4>3、 用户的帐号、密码和安全性</h4>
                <p>一旦成功注册成为会员，你将有一个密码和用户名（第三方登录除外）用户将对用户名和密码的安全负全部责任。另外，每个用户都要对以其用户名进行的所有活动和事件负全责。你可以随时改变你的密码。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告本公司。</p>
                <h4>4、拒绝提供担保</h4>
                <p>用户明确同意使用本公司服务，由用户个人承担风险。 本网站及APP不担保服务一定满足用户的要求，也不担保服务不会中断，对服务的及时性、安全性、出错发生都不作担保。 用户理解并接受：任何通过服务取得的信息资料的可靠性有用性取决于用户自己的判断，用户自己承担所有风险和责任。</p>
                <h4>5、有限责任</h4>
                <p>本网站及APP对任何由于使用服务引发的直接、间接、偶然及继起的损害不负责任。这些损害可能来自（包括但不限于）：不正当使用服务，或传送的信息不符合规定等。</p>
                <h4>6、对用户信息的存储和限制</h4>
                <p>本网站及APP不对用户发布信息的删除或储存失败负责，本公司有判定用户的行为是否符合服务条款的要求和精神的保留权利。如果用户违背了服务条款的规定，有中断对其提供服务的权利。</p>
                <h4>7、结束服务</h4>
                <p>本网站及APP可随时根据实际情况中断一项或多项服务，不需对任何个人或第三方负责或知会。 同时用户若反对任何服务条款的建议
                    或对后来的条款修改有异议，或对服务不满，用户可以行使如下权利： **(1) **不再使用本公司的服务。
                    **(2) **通知本公司停止对该用户的服务。</p>
                <h4>8、信息内容的所有权</h4>
                <p>本公司的信息内容包括：文字、软件、声音、相片、录象、图表；以及其它信息，所有这些内容受版权、商标、标签和其它财产所有权法律的保护。用户只能在授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
                <h4>9、适用法律</h4>
                <p>上述条款将适用中华人民共和国的法律，所有的争端将诉诸于本网所在地的人民法院。如发生服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持约束力。</p>
                <h4>10、其他服务</h4>
                <p>软件可能在某些功能上进行VIP收费服务，具体收费金额以软件收费页面实时获取为准。</p>
                <h4>11、联系我</h4>
                <p>邮箱地址：pengappdev@qq.com</p>
                <p style={{ marginTop: '20px' }}>更新日期：2024年09月28日<br />生效日期：2024年09月28日</p>
            </div>
        )
    } else if (language === '繁体') {
        return (
            <div>
                <p>歡迎閱讀《代購管家App用戶條款》（以下簡稱「本條款」）。本條款闡述之條款適用於你（以下簡稱「用戶」）使用App的各種產品和服務。</p>
                <h4>1、服務內容</h4>
                <p>
                    1.1 代購管家App為用戶提供訂單、客戶、商品管理服務。代購管家App有權對軟體提供的服務進行升級和調整，所有功能調整均以App內容更新的方式通知用戶，不單獨進行通知。
                </p>
                <p>
                    1.2 代購管家App不會對用戶信息進行任何二次利用，進行銷售等任何形式的獲利。
                </p>
                <p>
                    1.3 代購管家App僅提供技術相關服務，除此之外的網絡相關設備（如個人手機、平板等）及所需費用（如手機流量費用、WIFI上網費用等）均由用戶自行承擔。
                </p>
                <h4>2、服務條款的修改</h4>
                <p>
                    本網站及APP有權在必要時修改條款，將會在頁面公布。如果不接受所改動的內容，用戶可以主動取消自己的會員資格。如果你不取消自己的會員資格，則視為接受服務條款的變動。
                </p>
                <h4>3、用戶的帳號、密碼和安全性</h4>
                <p>
                    一旦成功註冊成為會員，你將有一個密碼和用戶名（第三方登錄除外）用戶將對用戶名和密碼的安全負全部責任。每個用戶都要對以其用戶名進行的所有活動和事件負全責。你可以隨時改變你的密碼。用戶若發現任何非法使用用戶帳號或存在安全漏洞的情況，請立即通告本公司。
                </p>
                <h4>4、拒絕提供擔保</h4>
                <p>
                    用戶明確同意使用本公司服務，由用戶個人承擔風險。本網站及APP不擔保服務一定滿足用戶的要求，也不擔保服務不會中斷，對服務的及時性、安全性、出錯發生都不作擔保。用戶理解並接受：任何通過服務取得的信息資料的可靠性有用性取決於用戶自己的判斷，用戶自己承擔所有風險和責任。
                </p>
                <h4>5、有限責任</h4>
                <p>
                    本網站及APP對任何由於使用服務引發的直接、間接、偶然及繼起的損害不負責任。這些損害可能來自（包括但不限於）：不正當使用服務，或傳送的信息不符合規定等。
                </p>
                <h4>6、對用戶信息的存儲和限制</h4>
                <p>
                    本網站及APP不對用戶發布信息的刪除或儲存失敗負責，本公司有判定用戶的行為是否符合服務條款的要求和精神的保留權利。如果用戶違背了服務條款的規定，有中斷對其提供服務的權利。
                </p>
                <h4>7、結束服務</h4>
                <p>
                    本網站及APP可隨時根據實際情況中斷一項或多項服務，不需對任何個人或第三方負責或知會。同時用戶若反對任何服務條款的建議或對後來的條款修改有異議，或對服務不滿，用戶可以行使如下權利： **(1) **不再使用本公司的服務。 **(2) **通知本公司停止對該用戶的服務。
                </p>
                <h4>8、信息內容的所有權</h4>
                <p>
                    本公司的信息內容包括：文字、軟體、聲音、相片、錄像、圖表；以及其他信息，所有這些內容受版權、商標、標籤和其他財產所有權法律的保護。用戶只能在授權下才能使用這些內容，而不能擅自複製、再造這些內容、或創造與內容有關的派生產品。
                </p>
                <h4>9、適用法律</h4>
                <p>
                    上述條款將適用中華人民共和國的法律，所有的爭端將訴諸於本網所在地的人民法院。如發生服務條款與中華人民共和國法律相抵觸時，則這些條款將完全按法律規定重新解釋，而其他條款則依舊保持約束力。
                </p>
                <h4>10、其他服務</h4>
                <p>
                    軟體可能在某些功能上進行VIP收費服務，具體收費金額以軟體收費頁面即時取得為準。
                </p>
                <h4>11、聯絡我</h4>
                <p>信箱：pengappdev@qq.com</p>
                <p style={{ marginTop: '20px' }}>更新日期：2024年09月28日<br />生效日期：2024年09月28日</p>
            </div>
        );
    } else {
        return (
            <div>
                <p>Welcome to the Terms of Use for the ShopMaster App (hereinafter referred to as "Terms"). These Terms apply to you (hereinafter referred to as "User") when using various products and services offered by the App.</p>
                <h4>1. Services Provided</h4>
                <p>
                    1.1 The ShopMaster App provides services for managing orders, customers, and products. The App reserves the right to upgrade and modify the services offered. All changes will be communicated through updates in the App, without separate notifications.
                </p>
                <p>
                    1.2 The ShopMaster App will not use user information for any secondary purposes, including sales or any form of profit-making.
                </p>
                <p>
                    1.3 The ShopMaster App only provides technical services. Users are responsible for their own internet-connected devices (such as personal phones, tablets, etc.) and any associated costs (like mobile data charges, WIFI fees, etc.).
                </p>
                <h4>2. Changes to Terms of Service</h4>
                <p>
                    The website and App have the right to modify these Terms as needed, which will be announced on the page. If you do not agree with the modified content, you may cancel your membership. If you do not cancel your membership, it will be deemed that you accept the changes to the Terms.
                </p>
                <h4>3. User Account, Password, and Security</h4>
                <p>
                    Once you have successfully registered as a member, you will receive a password and username (excluding third-party logins). Users are fully responsible for the security of their username and password. Additionally, each user is responsible for all activities and events conducted under their username. You can change your password at any time. If a user discovers any unauthorized use of their account or any security vulnerabilities, please notify the company immediately.
                </p>
                <h4>4. Disclaimer of Warranties</h4>
                <p>
                    Users explicitly agree to use the company's services at their own risk. The website and App do not guarantee that the services will meet user requirements, nor do they guarantee that services will not be interrupted or that the timeliness, security, or accuracy of the services is guaranteed. Users understand and accept that the reliability and usefulness of any information obtained through the services depend on their own judgment, and users assume all risks and responsibilities.
                </p>
                <h4>5. Limitation of Liability</h4>
                <p>
                    The website and App are not responsible for any direct, indirect, incidental, or consequential damages resulting from the use of the services. These damages may arise from (including but not limited to): improper use of the services or non-compliance of transmitted information.
                </p>
                <h4>6. Storage and Restrictions on User Information</h4>
                <p>
                    The website and App are not responsible for the deletion or failure to store user-published information. The company reserves the right to determine whether a user's actions comply with the requirements and spirit of these Terms.
                </p>
                <h4>7. Termination of Services</h4>
                <p>
                    The website and App may suspend one or more services at any time without liability or notice to any individual or third party. If a user disagrees with any of the service terms or has objections to any future modifications, or is dissatisfied with the services, they may exercise the following rights: **(1)** stop using the company's services. **(2)** notify the company to discontinue services to that user.
                </p>
                <h4>8. Ownership of Content</h4>
                <p>
                    The content provided by the company includes: text, software, audio, images, videos, graphics; and other information. All of this content is protected by copyright, trademarks, and other property rights laws. Users may only use this content under authorized conditions and are not permitted to reproduce, alter, or create derivative products from the content without permission.
                </p>
                <h4>9. Applicable Law</h4>
                <p>
                    These Terms are governed by the laws of the People's Republic of China, and all disputes will be submitted to the competent people's court where the website is located. If any service term conflicts with the laws of the People's Republic of China, those terms will be reinterpreted according to legal regulations, while the remaining terms will remain in effect.
                </p>
                <h4>10. Additional Services</h4>
                <p>
                    The software offers VIP services for certain features.
                </p>
                <p>
                    Currently, there are monthly, annual, and lifetime memberships. Fees are charged as a one-time payment and will not be recurring. Membership benefits will automatically expire upon reaching the expiration date. The specific fee amounts can be found on the software's charging page in real time.
                </p>
                <h4>Contact Us</h4>
                <p>Email: pengappdev@qq.com</p>
                <p style={{ marginTop: '20px' }}>Last updated: September 28, 2024<br />Effective date: September 28, 2024</p>
            </div>
        );
    }
}

export default Term;