import { useState } from 'react';
import './daigou.css'
import { Link, useSearchParams } from 'react-router-dom';


const translations = [
    {
        title: '关于',
        p1: 'Hi，我是Peng，由于我生活在广东，所以经常会去香港玩，身边也经常会有朋友托我代购一些香港的商品。每次我都会在电脑上用Excel把数据一一记录下来，这非常麻烦，而且Excel在手机上阅读和修改非常不方便，很容易就错乱了。',
        p2: '基于这个前提，我开发了代购管家App，希望能在日常代购中帮到你。当然，如果你在做微商或者其他生意，也可以用它来管理订单、客户、商品等。',
        title1: '联系我',
        tp1: '如果使用中遇到问题，或者你有一些好的建议，欢迎联系我。你可以',
        tp2: '微信扫码联系',
        tp3: '或者发邮件给我：',
        title2: '隐私',
        t2p1: '我们非常尊重用户的隐私，用户存储的所有信息都将加密，我们不会对数据进行任何分析和浏览，更不会给与任何第三方。具体可以参阅',
        privacy: '《隐私政策》',
        term: '《使用条款》',
        version: '版本信息',
        versiont: '当前版本',
        footer: '© 2024 代购管家. 保留所有权利。',
        close: '关闭',
        emailSuccess: '邮箱地址已复制到剪贴板',
        emailFail: '复制失败:',
    },
    {
        title: '關於',
        p1: 'Hi，我是Peng，由於我住在廣東，所以常常會去香港玩，身邊也常會有朋友託我代購一些香港的商品。每次我都會在電腦上用Excel把資料一一記錄下來，這非常麻煩，而且Excel在手機上閱讀和修改非常不方便，很容易就錯亂了。 ',
        p2: '基於這個前提，我開發了代購管家App，希望能在日常代購中幫助你。當然，如果你在做微商或其他生意，也可以用它來管理訂單、客戶、商品等。 ',
        title1: '聯絡我',
        tp1: '如果使用中遇到問題，或者你有一些很好的建議，歡迎聯絡我。你可以',
        tp2: '微信掃碼聯絡',
        tp3: '或寄email給我：',
        title2: '隱私',
        t2p1: '我們非常尊重用戶的隱私，用戶儲存的所有資訊都將加密，我們不會對資料進行任何分析和瀏覽，更不會給與任何第三方。具體可以參閱',
        privacy: '《隱私權政策》',
        term: '《使用條款》',
        version: '版本資訊',
        versiont: '當前版本',
        footer: '© 2024 代購管家. 保留所有權利。 ',
        close: '關閉',
        emailSuccess: '郵件地址已複製到剪貼簿',
        emailFail: '複製失敗:',
    },
    {
        title: 'About Us',
        p1: 'Hi, I’m Peng! Living in Guangdong means I often take trips to Hong Kong, where my friends frequently ask me to help them purchase various products. Each time, I find myself logging everything into Excel on my computer, which is quite a hassle. Plus, using Excel on my phone to read or edit the data is really inconvenient and often leads to mistakes.',
        p2: 'With this in mind, I developed ShopMaster app to make your daily shopping experience easier. Of course, if you’re running a small business or doing e-commerce, you can also use it to manage orders, clients, products, and more.',
        title1: 'Get in Touch',
        tp1: 'If you encounter any issues or have suggestions, I’d love to hear from you! You can',
        tp2: 'scan my WeChat QR code to connect',
        tp3: 'or email me at:',
        title2: 'Privacy',
        t2p1: 'We take your privacy seriously. All the information you store is encrypted, and we won’t analyze or access your data—let alone share it with any third parties. For more details, please refer to our',
        privacy: '《Privacy Policy》',
        term: '《Terms of Service》',
        version: 'Version Information',
        versiont: 'Current Version',
        footer: '© 2024 ShopMaster. All rights reserved.',
        close: 'Submit',
        emailSuccess: 'The email address has been copied to the clipboard',
        emailFail: 'Copy failed:',
    }
];

function Index() {
    // //使用useSearchParams获取查询参数
    const [searchParams] = useSearchParams();
    const theme = searchParams.get('theme');
    const language = searchParams.get('language') || '英文';
    const device = searchParams.get('device');//设备
    const [isOpen, setIsOpen] = useState(false);

    const newTheme = theme === 'dark' ? 'dark' : 'light';
    document.body.className = newTheme;

    const t = language === '中文' ? translations[0] : (language === '繁体' ? translations[1] : translations[2]); // 其他语言默认使用英文

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    const copyEmail = () => {
        const email = 'pengappdev@qq.com';
        navigator.clipboard.writeText(email)
            .then(() => {
                alert(t.emailSuccess);
            })
            .catch(err => {
                console.error(t.emailFail, err);
            });
    }

    return (
        <div style={{ alignItems: 'center' }}>
            <h3>{t.title}</h3>
            <p>{t.p1}</p>
            <p>{t.p2}</p>
            <h3>{t.title1}</h3>
            <p>{t.tp1}<a href="#" onClick={togglePopup}>{t.tp2}</a>{t.tp3}<a href="#" onClick={copyEmail}>pengappdev@qq.com</a>。</p>

            <h3>{t.title2}</h3>
            <p>{t.t2p1}<Link to={device === 'ios' ? `/daigougj/privacyios?language=${language}&theme=${theme}` : `/daigougj/privacyandroid?language=${language}&theme=${theme}`}>{t.privacy}</Link>&<Link to={`/daigougj/term?language=${language}&theme=${theme}`}>{t.term}</Link>。</p>
            <h3>{t.version}</h3>
            <p>{t.versiont}: v1.0.0</p>

            <footer>
                {language === '中文' && <p>App备案号：粤ICP备2021177159号-3A</p>}
                <p>{t.footer}</p>
            </footer>


            {isOpen && (
                <div style={popupStyles}>
                    <div style={overlayStyles} onClick={togglePopup}></div>
                    <img src={require('./wechat.jpg')} alt="微信扫码" style={imageStyles} />
                    <button onClick={togglePopup} style={closeButtonStyles}>{t.close}</button>
                </div>
            )}

        </div >
    )
}

const popupStyles: React.CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    width: '300px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '12px',
};

const overlayStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
};

const imageStyles: React.CSSProperties = {
    maxWidth: '90%',
    maxHeight: '90%',
    display: 'block', // 使其为块级元素
    margin: '20px auto', // 自动左右边距
};

const closeButtonStyles = {
    display: 'block',
    width: '90%',
    height: '40px',
    margin: '0px auto',
    fontSize: '16px',
    color: '#0096ff'
};

export default Index;