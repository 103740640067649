import '../css/index.css'
import screenImg from './screen.png'
import appicon from './appicon.png'
import { Helmet } from 'react-helmet-async';

function index() {
    const newTheme = 'nopadding';
    document.body.className = newTheme;
    return (
        <div>
            <Helmet>
                <title>代购管家ShopMaster</title>
                {/* <link rel="icon" href="/favicon.ico" /> */}
            </Helmet>
            <div className='gradienty-one'>
                <img className='left-image' src={screenImg} />

                <div className='right-div'>
                    <img className='appicon' src={appicon} />
                    <p className='title'>代购管家ShopMaster</p>
                    <div className='detail'>
                        <p>为你提供订单、客户和商品管理功能</p>
                        <p>· 快速上手，没有多余的功能，轻松记账</p>
                        <p>· 直观订单跟踪和管理，防止遗漏</p>
                        <p>· 客户管理，挖掘客户价值</p>
                        <p>· 库存管理，囤不囤货都可以使用</p>
                        <p>· 图形化数据分析，纵览生意全局</p>
                        <p>· 自动按汇率计算利润</p>
                        <p>· 数据实时联网，不怕丢失</p>
                        {/* <p>全平台，免费</p> */}
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div style={{ display: 'flex' }}>
                    <a href='./daigougj/privacyios'><p>《用户隐私协议》</p></a>
                    <a href='./daigougj/term'><p>《使用条款》</p></a>
                </div>

                <div style={{ display: 'flex', marginTop: 12 }}>
                    <a href='https://beian.miit.gov.cn/'><p style={{ marginRight: 20, fontSize: 14 }}>粤ICP备2021177159号-2</p></a>
                    <p style={{ fontSize: 14 }}>Copyright © 2024 野离子网络科技. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default index;