import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Suntable from './suntable'

import QingbojiPrivacyAndroid from './qingboji/privacyandroid'
import QingbojiTerm from './qingboji/term'
import QingbojiIndex from './qingboji'
import QingbojiPrivacyiOS from './qingboji/privacyios'

import DaigouGJPrivacyAndroid from './daigougj/privacyandroid'
import DaigouGJTerm from './daigougj/term'
import DaigouGJIndex from './daigougj'
import DaigouGJPrivacyiOS from './daigougj/privacyios'
import DaigouGJAbout from './daigougj/about'

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                {/* 根路径 */}
                <Route path='/' element={<Suntable />} />
                {/* 清波记App */}
                <Route path='qingboji' element={<QingbojiIndex />} />
                <Route path="qingboji/privacyandroid" element={<QingbojiPrivacyAndroid />} />
                <Route path="qingboji/term" element={<QingbojiTerm />} />
                <Route path='qingboji/privacyios' element={<QingbojiPrivacyiOS />} />

                {/* 代购管家SunTable */}
                <Route path='daigougj' element={<DaigouGJIndex />} />
                <Route path="daigougj/privacyandroid" element={<DaigouGJPrivacyAndroid />} />
                <Route path="daigougj/Term" element={<DaigouGJTerm />} />
                <Route path='daigougj/privacyios' element={<DaigouGJPrivacyiOS />} />
                <Route path='daigougj/about' element={<DaigouGJAbout />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;